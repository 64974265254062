<template>
  <div v-if="isPermission">
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol md="10" sm="9" col="9">
              <div class="row">
                <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                <select
                  class="custom-select col-11"
                  v-model="shopObjectId"
                  disabled
                >
                  <option selected>{{ $t('selectshop') }}</option>
                  <option
                    v-for="shop in shops"
                    :key="shop.objectId"
                    :value="shop.objectId"
                  >
                    {{ shop.shopName }} - {{ shop.branchName }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="3" md="2" col="3">
              <router-link :to="{ name: 'PaymentReport' }" replace>
                <CButton block color="light">
                  <CIcon name="cil-arrow-thick-from-right" />{{ $t('back') }}
                </CButton>
              </router-link>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 class="col-md-10 col-sm-9 col-9">
              {{ $t('report.txtDeliveryRoport') }}
            </h2>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-3 text-right text-success"
            >
              <CButton v-on:click="exportPaymentReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <CRow v-if="data.length != 0">
                <CCol class="col-md-5">
                  <CCard class="d-flex align-items-center">
                    <CCardBody>
                      <CChartDoughnut
                        style="height: 300px"
                        :datasets="paymentChart.datasets"
                        :labels="paymentChart.labels"
                        :options="doughnutOptions"
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol class="col-md-7">
                  <CRow>
                    <CCardBody
                      class="col-6"
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <CCallout color="success">
                        <h5 class="text-success">
                          {{ item.deliveryProvider }}
                        </h5>
                        <div class="d-flex">
                          <div class="flex-fill">
                            <strong class="h6 text-muted">
                              Cash
                            </strong>
                          </div>
                          <div class="flex-fill text-right">
                            <strong class="h6 text-muted">
                              {{ item.paidByCash }}
                            </strong>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="flex-fill">
                            <strong class="h6 text-muted">
                              Credit
                            </strong>
                          </div>
                          <div class="flex-fill text-right">
                            <strong class="h6 text-muted">
                              {{ item.paidByProvider }}
                            </strong>
                          </div>
                        </div>
                      </CCallout>
                    </CCardBody>
                  </CRow>
                </CCol>
              </CRow>
              <DataTable
                style="white-space: nowrap;"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
              >
              </DataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CChartDoughnut } from '@coreui/vue-chartjs'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import util from '@/util/util'
import permis from '@/util/permission'
import DataTable from '@/containers/DataTable'

export default {
  components: {
    CChartDoughnut,
    DataTable,
  },
  data() {
    return {
      data: [],
      details: [],
      deliveryDetail: [],
      summary: {},
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', '/report/payment')
    },
    isExport() {
      return permis.findPermissionExport('report', '/report/payment')
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    paymentChart() {
      let delivery = this.deliveryDetail
      const data = []
      const labels = []
      for (let i = 0; i < delivery.length; i++) {
        labels.push(delivery[i].deliveryProvider)
        data.push(delivery[i].paidByCash + delivery[i].paidByProvider)
      }
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: [
              '#5CBA96',
              '#F29089',
              '#4CB2D4',
              '#F6CE3B',
              '#A9A7D6',
              '#BA5C80',
              '#9992D1',
            ],
            data: data,
            borderWidth: 1,
          },
        ],
      }
    },
    doughnutOptions() {
      return {
        legend: {
          position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        {
          key: 'deliveryProvider',
          label: this.$i18n.t('deliveryProvider'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        { key: 'paidByCash', label: 'Cash', _classes: 'text-right text-dark font-weight-normal', },
        { key: 'paidByProvider', label: 'Credit', _classes: 'text-right text-dark font-weight-normal', },
        {
          key: 'total',
          label: this.$i18n.t('payTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    items() {
      let data = this.deliveryDetail || []
      let objects = []
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let total = (item.paidByProvider || 0) + (item.paidByCash || 0)
        objects.push({
          id: i + 1,
          deliveryProvider: item.deliveryProvider || '-',
          paidByCash: util.convertCurrency(item.paidByCash || 0),
          paidByProvider: util.convertCurrency(item.paidByProvider || 0),
          total: util.convertCurrency(total),
        })
      }
      return objects
    },
  },
  created() {
    this.getPaymentReport()
  },
  methods: {
    ...util,
    getPaymentReport() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/payment/v1.0/getreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.dates
          this.summary = res.data.data.summary
          this.deliveryDetail = res.data.data.summary.deliveryDetail
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPaymentReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/payment/v1.0/getreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานการชำระเงิน.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item) {
      const position = this.details.indexOf(item)
      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(item)
    },
  },
}
</script>
